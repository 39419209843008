<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
    <submenu-server menu="xilinx"></submenu-server>

        <div class="submenu-content">
          <div v-if="error" class="alert alert-danger">{{ error }}</div>

          <!-- show page content -->
          <div v-if="item">
            <div v-for="(b, index) in item.body">

              <div v-if="b.type === 'heading'">
                <div v-if="b.value === 'Inquiry Button'">
                  <br/>
                  <router-link class="btn btn-primary btn-lg" to="/contact-us/server">Inquiry/Setup Account</router-link>
                  <br/><br/>
                </div>
                <div v-else>
                  <h4>{{b.value}}</h4>
                </div>
              </div>

              <div v-else-if="b.type === 'paragraph'" class="paragraph">
                <p v-html="b.value"></p>
              </div>

              <div v-else-if="b.type === 'image'">
                <img
                :src="b.value.large.src"
                :width="b.value.large.width"
                :height="b.value.large.height">
              </div>

              <!-- page title -->
              <div v-else-if="b.type === 'block'">
              
                <div v-if="b.value.heading === 'Real-Time Video Server Appliance'">
                  <div class="float-start">
                    <img :src="b.value.image.medium.src"
                    :width="b.value.image.medium.width"
                    :height="b.value.image.medium.height">
                  </div>
                  <div class="float-start title"><h2>{{b.value.heading}}</h2></div>
                  <div class="clear"></div>
                  <br/><br/>
                </div>

              </div>

              <!-- stream block -->
              <div v-else-if="b.type === 'stream_block'"> 
                <div v-for="(sb, index) in b.value" class="float-start">
                  
                  <!-- partner venders logo list -->
                  <div v-if="sb.value.name === 'partner venders'">
                    <div v-if="sb.value.heading" class="underline"><h4>{{sb.value.heading}}</h4></div>
                    <div v-if="sb.value.image_stream">
                      <div v-for="itm in sb.value.image_stream" class="float-start pad">            
                          <img :src="itm.value.medium.src"
                          height="100">
                      </div>
                    </div>
                  </div>

                  <!-- High Density Video Appliance and Bitrate Optimized Appliance-->
                  <div v-else-if="sb.value.name === 'alveo'" class="float-start block2">
                      <img :src="sb.value.image_stream[0].value.large.src" 
                          width="450">                    
                      <div v-if="sb.value.paragraph" v-html="sb.value.paragraph" class="paragraph"></div>
                  </div>

                  <!-- three horizonal images --> 
                  <div v-else-if="sb.value.name === 'images'" class="blockimg">
                      <center><img :src="sb.value.image_stream[0].value.large.src" 
                              width="150" > </center>
                      <center>{{sb.value.heading}}</center>
                  </div>

                  <!-- Applications -->
                  <div v-else-if="sb.value.name === 'application'" class="blockimg_app">
                      <center><img :src="sb.value.image_stream[0].value.medium.src" 
                              :width="sb.value.image_stream[0].value.medium.width"
                              :height="sb.value.image_stream[0].value.medium.height"> </center>
                      <center>{{sb.value.heading}}</center>
                  </div>
                  
                  <!-- Accelerator Cards -->
                  <div v-else-if="sb.value.name === 'accelerator card'" class="float-start block2">
                      <div v-if="sb.value.heading"><h6>{{sb.value.heading}}</h6></div>
                      <img :src="sb.value.image_stream[0].value.large.src" 
                          width="450">                    
                      <div v-if="sb.value.paragraph" v-html="sb.value.paragraph" class="paragraph"></div>
                  </div>

                </div>
                <div class="clear"></div>
              </div>
              <!-- end of stream block-->
              
            </div>
          </div>

          <!-- end of page content -->

  </div>
  </div>
</template>

<script>
import SubmenuServer from '../../components/nav/SubmenuServer.vue'
import BreadcrumbPage from "../../components/BreadcrumbPage.vue"
import { ref, onMounted } from 'vue'
import { getAPI } from '../../utils/axios-api'

export default {
  name: 'XilinxSolutions',
  components: { SubmenuServer, BreadcrumbPage },
  setup() {
    const error = ref('')
    const item = ref({})
    
    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "Server", link: ""})
    breadcrumb_links.value.push({text: "Server Solutions", link: "ServerSolutions"})
    breadcrumb_links.value.push({text: "Xilinx Real-Time Video Server", link: "XilinxSolutions"})

    getAPI
    .get('/content/pages/40')
    .then(response => {
      item.value = response.data
    })
    .catch(err => {
      error.value = err
    })

    return { error, item, breadcrumb_links }
  }
}
</script>

<style scoped>
.clear {clear:both;}
.padl {margin-left:50px;}
.pad {margin-right:50px;}
.paragraph {margin:30px 0;}
.block3 {width:30%; margin: 50px 30px 10px 0; height: 300px;}
.block2 {width:450px; margin: 50px 30px 10px 0; min-height: 300px;}
.blockimg {width:200px; margin-left:100px; margin-bottom:100px; } 
.blockimg_app {margin: 20px 20px 50px 0;}
.underline {border-bottom:1px solid #888;}

.title {padding:40px 0 0 50px; }

</style>